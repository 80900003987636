import React, { useEffect } from 'react';
import logo from './../../logo.svg';
import './App.css';
import PropTypes from "prop-types";
import { Container, Box, Typography, Tabs, Tab, Button, Paper, Card, CardContent, CardActionArea, CardActions, Fade, Collapse, Grid } from '@mui/material';




function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography 
       component="div"
       role="tabpanel"
       hidden={value !== index}
       id={`scrollable-auto-tabpanel-${index}`}
       aria-labelledby={`scrollable-auto-tabpanel-${index}`}
       {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography> );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

function App() {
//  const classes = useStyles();
  const [data, setData] = React.useState({});
  const [value, setValue] = React.useState(0);
  const [expandedId, setExpandedId] = React.useState(-1);
  var tabs;
  var panels;

  const getData=()=>{
    fetch('legends-menu.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(  (response) => {
      return response.json();
    })
    .then((myJson) => {
         setData(myJson);
    });
  }
  useEffect(() => { getData(); },[]);

  function handleChange(event, newValue) {
    setValue(newValue);
  }
  const handleExpandClick = i => {
    setExpandedId(expandedId === i ? -1 : i);
  } 

  function menuAdds(item) {
    if (item.add) {
      return (<span class='addons'>{item.add}<br/></span>);
    } else {
      return ;
    }
  }
  function DetailOptions(item, i) {
    if (item.details) {
      return ([ <Collapse in={expandedId === i} timeout="auto" unmountOnExit>
                  <Typography><strong>{item['details-label']}</strong><br/></Typography>
                  <Typography variant="body2" align="left" marginLeft={10}>
                    <Grid container spacing={1}>
                      { item.details.map( (item2, i) => <Grid item xs={6}>{item2}</Grid> ) }                  
                    </Grid>
                  </Typography>
            </Collapse>,
            <CardActions>
              <Button size="small" onClick={ () => handleExpandClick(i)}>{ expandedId === i ? 'Hide' : 'Show' } {item['details-label'] }</Button>
            </CardActions>
            ]);
    } else {
      return ;
    }
  }
  
  if (data) {
    tabs = Object.keys(data).map( (item, i) =>  <Tab label={item} {...a11yProps(i)} /> );
    var sections = Object.keys(data);
     
    panels = sections.map( (item, i,x) => 
                          <TabPanel value={value} index={i} >                                           
                            { data[item].map((item2,i2) => 
                            <Card key={item2.name+i2}>
                              <CardContent>
                              <strong>{item2.name}</strong><br/>
                              {item2.description}<br/>
                              { Array.isArray(item2.price) ? item2.price.map((price, i3, allPrices) =>
                                <div Style="display: inline; padding: 1rem;">{item2['price-label'][i3]} <strong>{allPrices[i3].toFixed(2)}</strong> </div>
                               ) : 
                                <strong>{item2.price.toFixed(2)} 
                                <Typography variant="subtitle2">{item2.starter && 'starter'} {item2.starter && item2.starter.toFixed(2)}
                                </Typography>
                                </strong>}
                              <br/>
                              {item2.add && menuAdds(item2)}
                              </CardContent>
                             {DetailOptions(item2, (i*1000)+i2) }
                             </Card>
                            ) }
                           </TabPanel>);
  
  }

  return (
    <Container maxWidth="sm" className="App">
      <Paper variant="outlined">
        <Tabs value={value} onChange={handleChange} arial-label="basic tabs example" variant="scrollable">
          { tabs } 
        </Tabs>
        { panels }
        <Typography variant="subtitle2">
          Taxes not included. Prices and menu items subject to change.
        </Typography>
      </Paper>
    </Container>
  );
}
export default App;
